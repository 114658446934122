export function headermove(){
    let header = document.querySelector('header.main');
    let movedel = document.querySelector('.grys')
    header?.addEventListener('mousemove', function(e){
      let Cord = {
        x: e.clientX,
        y: e.clientY
      };

      movedel.style.left = `${Cord.x}px`;
 
    })

}