import { headermove } from '../js/module/effect.js';
import { Web4you_slider } from  '../js/module/slider.js';
require('./bootstrap');
 
// header effect
headermove();

//slideris instalacia
Web4you_slider();

let input = document.querySelectorAll('.inpsec input');

input.forEach(el=>{
  el.addEventListener('change', function(e){
    appnd.innerHTML = '';
  let checkedinput = document.querySelectorAll('.inpsec input:checked');
  let sum = 0;
  checkedinput.forEach(inp => {
    appnd.innerHTML  += `<li>${inp.parentElement.innerText}: ${inp.value} ₾ </li>`;
    sum += Number(inp.value.replace('+', ''));
  })

  sums.innerText = parseFloat(sum);

  })
})

window.addEventListener('load', function(){
  let video =   document.querySelector('#headervideo');
  if (!video) {
    return;
  }
  let src = video.getAttribute('data-src');
  let loader = document.querySelector('#nrp');
  loader.classList.add('hide');
  video.src = src;
  setTimeout(() => {
    loader.remove();
    video.classList.add('load');
    
  }, 300);
})


let togbtn = document.querySelector('.navbar-togg');
let menu = document.querySelector('.collapse');

togbtn.addEventListener('click',function(){
  togbtn.classList.toggle('show');
  menu.classList.toggle('showed');
})

 

let sliderconf;
let spacleft;
if (window.innerWidth > 992) {
  spacleft = 80;
}else{
  spacleft = 10;

 

}

var swiper = new Swiper(".mySwiper", {
    slidesPerView: "auto",
    spaceBetween: spacleft,
    speed:2200,
    pagination: {
      el: ".swiper-paginations",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
  });