
//   სლაიდერის კონტეინერი
let slider_container = document.querySelectorAll('.mein_slider');
 
// ყველა სლაიდი
let all_slider = document.querySelectorAll('.mein_slider_content');
 
// შემდეგზე გადართვა
let next_btn = document.querySelector('.slider_switch .next');

// წინაზე გადართვა
let prev_btn = document.querySelector('.slider_switch .prev');

export function Web4you_slider(){

    // შემდეგი
    next_btn?.addEventListener('click', function(){
        Next();
    })

    // წინა
    prev_btn?.addEventListener('click', function(){
        Prev();
    })

}

function Next(){
 
// აქტიური სლაიდერი
let actived_slider = document.querySelector('.mein_slider_content.active');
// აქტიურის შემდეგი სლაიდი
let next_slider = document.querySelector('.mein_slider_content.active').nextElementSibling;

prev_btn.disabled = false;
 
if (next_slider.nextElementSibling == null) {
    next_btn.disabled = true;
}else{
    next_btn.disabled = false;
}


   actived_slider.classList.add('prev');
 
setTimeout(()=>{
   
    actived_slider.classList.add('previus');
     
    setTimeout(() => {
        actived_slider.classList.remove('active');
   
        if (next_slider.classList.contains('right')) {
            next_slider.classList.replace('right', 'next');
        }else{
             next_slider.classList.add('next');
        }
       
        
        setTimeout(() => {
            next_slider.classList.remove('next', 'prev', 'right');
            next_slider.classList.add('active');
 
        }, 400);
 
    }, 200);
 

}, 800)

}

function Prev(){
    // აქტიური სლაიდერი
let actived_slider = document.querySelector('.mein_slider_content.active');
// აქტიურის შემდეგი სლაიდი
let prev_slider = document.querySelector('.mein_slider_content.active').previousElementSibling;

next_btn.disabled = false;

if (prev_slider.previousElementSibling == null) {
    prev_btn.disabled = true;
}else{
    prev_btn.disabled = false;
}
 
actived_slider.classList.add('prev');
 
setTimeout(()=>{
   
    actived_slider.classList.add('right');
     
    setTimeout(() => {
        actived_slider.classList.remove('active');
        prev_slider.classList.replace('previus', 'active');
        
        setTimeout(() => {
            prev_slider.classList.remove('prev', 'right');
       
        }, 400);
 
    }, 200);
 

}, 800)
}